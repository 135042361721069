/*
 * @Description: 
 * @Version: 2.0
 * @Autor: cjw
 * @Date: 2024-11-05 16:03:37
 * @LastEditors: cjw
 * @LastEditTime: 2024-11-13 15:00:13
 */
const getters = {
  token: state => state.user.token,
  name: state => state.user.name,
  city: state => state.user.city,
  type: state => state.user.type,
  enterList: state => state.user.enterList
}
export default getters
