/*
 * @Description: 
 * @Version: 2.0
 * @Autor: cjw
 * @Date: 2024-11-05 16:03:37
 * @LastEditors: cjw
 * @LastEditTime: 2024-11-13 14:58:28
 */
import { getToken, setToken, removeToken } from '@/utils/auth'
// import { infantLogin } from '@/api'
// import request from '../../utils/request'

function getQueryVariable (variable) {
  const arr = window.location.search.split("?")
  if (arr.length <= 1) return

  var vars = arr[1].split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) { return pair[1]; }
  }
  return (false);
}

const user = {
  state: {
    token: getToken()||localStorage.getItem('token'),
    name: '',
    city:'杭州市',
    type: '',
    enterList: [],
    headersInfo: {},
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      console.log(name);
      state.name = name||'浙江省'
    },
    SET_CITY: (state, city) => {
      console.log(city);
      state.city = city||'杭州市'
    },
    SET_TYPE: (state, type) => {
      state.type = type
    },
    SET_ENTER: (state, data) => {
      state.enterList = data
    },
    SET_HEADERS: (state, data) => {
      state.headersInfo = data
    }
  },

  actions: {
    GET_TOKEN ({ commit }) {
      const token = getQueryVariable('token')|| getQueryVariable('cockpit')
      const name = getQueryVariable('name')
      if (!name) {
        commit('SET_NAME', localStorage.getItem('areaCode'))
      }else{
        commit('SET_NAME', decodeURI(name))

      }
      commit('SET_TOKEN', token)
      setToken(token)
      const type = getQueryVariable('type')
      if (type) {
        commit('SET_TYPE', type)
      } else {
        commit('SET_TYPE', '')
      }
    },
    // 前端 登出
    FedLogOut ({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
