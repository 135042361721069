import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import './style/main.scss'
import moment from 'moment'
import './permission'
import {
  Row, Col, Form,
  FormItem, Input,
  Button, Icon, Popover, Tooltip,Carousel,CarouselItem
} from 'element-ui';
import * as echarts from 'echarts';
import 'echarts-liquidfill';
import 'echarts-gl';
import './assets/font/font.css';
Vue.config.productionTip = false

Vue.use(Row).use(Col).use(Form).use(FormItem).use(Input).use(Button).use(Icon).use(Popover).use(Tooltip).use(Carousel).use(CarouselItem)
// Vue.prototype.$message = Message;
// Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$echarts = echarts
Vue.prototype.$moment = moment;
// Vue.prototype.$Vconsole = Vconsole;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
