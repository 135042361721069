import router from './router'
import store from './store'
// import moment from 'moment'
// import { Message } from 'element-ui'
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'

// NProgress.configure({ showSpinner: false })
// const nowTime = moment().format('YYYY-MM-DD HH:ss:mm')
// const nowTime = '2022-10-16 17:30:00'
// const startTime = '2022-10-15 15:30:00'
// const endTime = '2022-10-24 08:00:00'
const whiteList = ['/','/login', '/infanttv', 'infantlogin']
// if (name) {
//   localStorage.setItem('name', decodeURI(name))
// }
// console.log('window.location :>> ', window.location.pathname);
if (window.location.pathname !== '/infanttv') {
  store.dispatch('GET_TOKEN')
}
router.beforeEach((to, from, next) => {
  // if (Object.is(to.name, 'maintain')) {
  // next();
  // return
  // }

  // if (moment(nowTime).diff(moment(startTime), 'seconds') > 0 && moment(nowTime).diff(moment(endTime), 'seconds') <= 0 && to.path !== '/smoke' && to.path !== '/air') {
  // next('/maintain')
  // } else {
  if (getToken()||localStorage.getItem('token')) {
    /* has token*/
    if (store.getters.token) {
      // setToken(Authorization)
      // store.commit("SET_TOKEN", Authorization)
      // store.commit("SET_HOTELNAME", decodeURI(name))
      next()

    } else {
      if (to.path === '/login') {
        next()
        // NProgress.done()
      } else {
        // debugger
        next()
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next('https://zjiva.terabits.cn/') // 否则全部重定向到登录页
      // NProgress.done()
    }
  }
  // }


})

router.afterEach(() => {
  // NProgress.done()
})
