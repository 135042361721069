import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import getters from './getters'
import VuexPersist from 'vuex-persist'
Vue.use(Vuex)
const vuexLocal = new VuexPersist({
  storage: window.sessionStorage  // 可选存储方式
})
const store = new Vuex.Store({
  modules: {
    user,
  },
  getters,
  plugins: [vuexLocal.plugin]
})

export default store
